
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';
import { DateTime } from 'luxon';
import accountModule from '@/store/modules/accountModule';
import proceduresModule from '@/store/modules/proceduresModule';
import { v4 as uuidv4 } from 'uuid';

@Component({
  components: {
    SaveStep: () => getComponent('procedures/SaveStep'),
  },
})
export default class ProcedureItem extends Vue {
  procedure = null as any;

  showStepsComponent = false;

  isLoadingData = false;

  activeStep = null;
  showWorkflowMenu = false

  get user() {
    return accountModule.user;
  }

  get procedureItem() {
    return proceduresModule.activeProcedure as any;
  }

  get modifiedDate() {
    const date = DateTime.fromISO(`${this.procedureItem.createdAt}Z`);
    return `${date.toFormat('L/d/y')} at ${date.toFormat('h:mm a')}`;
  }

  get procedureToCopy() {
    const { id, ...procedure } = this.procedure as any;
    return procedure;
  }


  confirmDeleteOpen() {
    this.$dialog.confirm('Delete workflow?', 'This action cannot be undone.', 'info')
      .then((result) => {
        if (result.isOk) {
          // await this.$store.dispatch(DELETE_ASSIGNMENT, this.assignment);
         this.close();
        }
      })
  }


  editWorkflow() {
    this.$emit('change-component', 'update-workflow')
  }

  close() {
    this.$emit('close-left-component');
  }

  toggleWorkflowMenu() {
	  this.showWorkflowMenu = !this.showWorkflowMenu
  }

  closeWorkflowSidebar() {
	  this.$emit("closeComponentEvent", "")
  }
  async created() {
    this.procedure = JSON.parse(JSON.stringify(this.procedureItem));
  }

  toggleStepDetails(step) {
    if (!this.user.isAdmin) return;
    Object.assign(step, {
      showDetails: !step.showDetails,
    });
  }

  resetStepState({ object }) {
    if (object) {
      Object.assign(object, {
        showActions: false,
        showDetails: false,
      });
    }
  }

  editStep(step) {
    // this.$eventBus.$emit('procedureSaveStep.modal.toggle', {
    //   isVisible: true,
    //   procedure: this.procedure,
    //   previousStepOrder: step.order,
    //   step,
    // });
    // this.activeStepId = null;
    this.toggleStepDetails(step);
    this.activeStep = step;
    this.showStepsComponent = true;
  }

  toggleSaveStepModal() {
    const stepsLength = this.procedure.steps.length;
    this.procedure.steps.push({
      id: uuidv4(),
      order: stepsLength + 1,
      text: '',
      responses: ['Not applicable'],
      responsesDetails: [],
      exclusions: [],
      temporary: true,
      showActions: false,
      showDetails: false,
    });
    // this.$eventBus.$emit('procedureSaveStep.modal.toggle', {
    //   isVisible: true,
    //   procedure: this.procedure,
    //   step: this.procedure.steps[this.procedure.steps.length - 1],
    //   previousStepOrder: this.procedure.steps[this.procedure.steps.length - 1].order,
    //   newStep: true,
    // });
  }

  getProcedureDateString(historyDate) {
    const date = new Date(historyDate);
    return `${date.toLocaleDateString('en-us')} at ${date.toLocaleTimeString('en-us', { hour: '2-digit', minute: '2-digit' })} MST`;
  }

  async deleteProcedure() {
    const result = await this.$dialog.confirm('Delete workflow?', 'This action cannot be undone.', 'info')
	
    if (result.isOk) {
		
		this.isLoadingData = true
		await proceduresModule.deleteProcedure({ProcedureID: this.procedureItem.ProcedureID})
		this.isLoadingData = false
		this.$emit('reload-page')
    }
  }

  getResponseValue(stepId, response = '') {
    return (this.procedureItem.parsedResponsesDict[stepId] || {})[response]?.count || 0;
  }

  copyProcedure() {
    this.$emit('change-component', 'update-procedure')
  }

  editProcedure() {
    this.$emit('change-component', 'update-procedure')
  }

  onSaveStep(steps) {
	  this.procedure.steps = steps
    this.showStepsComponent = false;
  }
}
